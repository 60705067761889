<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal"
      ref="new_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Kupon Adı"
              label-for="name"
            >
              <b-form-input
                v-model="name"
                placeholder="Kupon Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Kupon Kodu"
              label-for="name"
            >
              <b-form-input
                v-model="discount_code"
                placeholder="Kupon Kodu"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="İndirim Türü"
              label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="type"
                  :options="type_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="İndirim Miktarı"
                label-for="name"
            >
              <b-form-input
                  v-model="discount_value"
                  placeholder="İndirim Miktarı"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Geçerli Plan Periyodu"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="plan_period"
                  :options="plan_period_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Çoklu Kullanım"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="multiple_use"
                  :options="multiple_use_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Kupon Sayısı"
              label-for="name"
            >
              <b-form-input
                v-model="max_use_count"
                placeholder="Kupon Sayısı"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kupon Geçerlilik Tarihi"
                label-for="name"
            >
              <b-form-datepicker
                  id="example-datepicker"
                  v-model="expiration_date"
                  class="mb-1"
              />
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
              variant="primary"
            class="mr-1"
            @click="new_record_add_button"
          >

            <span class="align-middle">{{ $t('core.btn_add') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
        id="update_record_modal"
        ref="update_record_modal"
        centered

        no-close-on-backdrop
        content-class="shadow"
        title="Kayıt Düzenle"
        footer-class="d-none"
        size="lg"
        @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kupon Adı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_name"
                  placeholder="Kupon Adı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kupon Kodu"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_discount_code"
                  placeholder="Kupon Kodu"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="İndirim Türü"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_type"
                  :options="type_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="İndirim Miktarı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_discount_value"
                  placeholder="İndirim Miktarı"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Geçerli Plan Periyodu"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_plan_period"
                  :options="plan_period_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Çoklu Kullanım"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_multiple_use"
                  :options="multiple_use_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kupon Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_max_use_count"
                  placeholder="Kupon Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kupon Geçerlilik Tarihi"
                label-for="name"
            >
              <b-form-datepicker
                  id="example-datepicker"
                  v-model="edit_expiration_date"
                  class="mb-1"
              />
              <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Kupon Durum"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_discount_status"
                  :options="discount_status_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-success"
              class="mr-1"
              @click="record_edit_button()"
          >
            <feather-icon
                icon="SaveIcon"
                class="mr-1"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
        id="delete_record_modal"
        ref="delete_record_modal"
        class="danger"
        centered

        no-close-on-backdrop
        content-class="shadow"
        title="Kayıt Sil"
        footer-class="d-none"
        size="lg"
        @hide="close_delete_record_modal"
    >
      <div>
        <b-card-text class="text-left">
          <span class="text-danger">{{ selected_row_name }}</span><span>isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-success"
              class="mr-1"
              @click="close_delete_record_modal()"
          >
            <feather-icon
                icon="XIcon"
                class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-danger"
              class="mr-1"
              @click="record_delete_button()"
          >
            <feather-icon
                icon="TrashIcon"
                class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <!-- Table Filter -->
    <b-card no-body class="mb-1 mx-1">
      <b-card-body>
        <b-row>
          <b-col
              cols="12"
              md="2"
          >
            <b-form-group
                label="İndirim Türü"
                label-for="i-plan"
            >
              <v-select
                  id="i-plan"
                  v-model="filter_type"
                  :options="filter_type_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>

          </b-col>
          <b-col
              cols="12"
              md="2"
          >
            <b-form-group
                label="Plan Periyot"
                label-for="i-plan"
            >
              <v-select
                  id="i-plan"
                  v-model="filter_plan_period"
                  :options="filter_plan_period_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>

          </b-col>
          <b-col
              cols="12"
              md="2"
          >
            <b-form-group
                label="Çoklu Kullanım"
                label-for="i-plan"
            >
              <v-select
                  id="i-plan"
                  v-model="filter_multiple_use"
                  :options="filter_multiple_use_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>

          </b-col>
          <b-col
              cols="12"
              md="2"
          >
            <b-form-group
                label="Kupon Durumu"
                label-for="i-plan"
            >
              <v-select
                  id="i-plan"
                  v-model="filter_discount_status"
                  :options="filter_discount_status_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>

          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Filter -->

    <b-card>
      <div class="mb-1">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                size="sm"
                class="d-inline-block mr-1"
                :placeholder="$t('core.search')"
              />
              <b-button
                v-b-modal.new_record_modal
                size="sm"
                variant="primary"
              >
                <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        responsive
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        class="text-nowrap"
        show-empty
        style="min-height: 500px"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
        small
        striped
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <div v-if="data.item.status === true">
              <b-badge variant="success">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Aktif</span>
              </b-badge>
            </div>
            <div v-if="data.item.status === false">
              <b-badge variant="danger">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Pasif</span>
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <div v-if="data.item.type === 'fixed'">
              <b-badge variant="warning">
                <feather-icon icon="MinusIcon" />
                <span class="align-middle ml-50">Sabit İndirim</span>
              </b-badge>
            </div>
            <div v-if="data.item.type === 'percentage'">
              <b-badge variant="secondary">
                <feather-icon icon="DivideIcon" />
                <span class="align-middle ml-50">Yüzde İndirim</span>
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(multiple_use)="data">
          <div class="text-nowrap">
            <div v-if="data.item.multiple_use === true">
              <b-badge variant="danger">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Evet</span>
              </b-badge>
            </div>
            <div v-if="data.item.multiple_use === false">
              <b-badge variant="success">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Hayır</span>
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(plan_period)="data">
          <div class="text-nowrap">
            <div v-if="data.item.plan_period === 'all'">
              <b-badge variant="danger">
                <span class="align-middle ml-50">Hepsi</span>
              </b-badge>
            </div>
            <div v-if="data.item.plan_period === 'annually'">
              <b-badge variant="secondary">
                <span class="align-middle ml-50">Yıllık Plan</span>
              </b-badge>
            </div>
            <div v-if="data.item.plan_period === 'monthly'">
              <b-badge variant="warning">
                <span class="align-middle ml-50">Aylık PLan</span>
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(id)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Düzenle</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Sil</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'status', label: 'Durum', sortable: true },
        { key: 'name', label: 'Kupon Adı', sortable: true },
        { key: 'discount_code', label: 'İndirim Kodu', sortable: true },
        { key: 'type', label: 'İndirim Türü', sortable: true },
        { key: 'discount_value', label: 'İndirim Miktarı', sortable: true },
        { key: 'plan_period', label: 'Plan Periyot', sortable: true },
        { key: 'multiple_use', label: 'Çoklu Kullanım', sortable: true },
        { key: 'max_use_count', label: 'Kupon Sayısı', sortable: true },
        { key: 'total_use_count', label: 'Kullanılma Sayısı', sortable: true },
        { key: 'expiration_date', label: 'Geçerlilik Tarihi', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,

      filter_type: { title: 'Hepsi', value: null },
      filter_type_options: [
        { title: 'Hepsi', value: null },
        { title: 'Sabit İndirim', value: "fixed" },
        { title: 'Yüzde İndirim', value: "percentage" },
      ],
      filter_plan_period: { title: 'Hepsi', value: null },
      filter_plan_period_options: [
        { title: 'Hepsi', value: null },
        { title: 'Bütün Planlar', value: "all" },
        { title: 'Yıllık Plan', value: "annually" },
        { title: 'Aylık Plan', value: "monthly" },
      ],
      filter_multiple_use: { title: 'Hepsi', value: null },
      filter_multiple_use_options: [
        { title: 'Hepsi', value: null },
        { title: 'Evet', value: true },
        { title: 'Hayır', value: false },
      ],
      filter_discount_status: { title: 'Hepsi', value: null },
      filter_discount_status_options: [
        { title: 'Hepsi', value: null },
        { title: 'Aktif', value: true },
        { title: 'Pasif', value: false }
      ],

      name: null,
      discount_code: null,
      type: { title: 'İndirim Türü', value: null },
      type_options: [
        { title: 'Sabit İndirim', value: "fixed" },
        { title: 'Yüzde İndirim', value: "percentage" },
      ],
      discount_value: null,
      plan_period: { title: 'Geçerli Plan Tütü', value: null },
      plan_period_options: [
        { title: 'Bütün Planlar', value: "all" },
        { title: 'Yıllık Plan', value: "annually" },
        { title: 'Aylık Plan', value: "monthly" },
      ],
      multiple_use: { title: 'Hayır', value: false },
      multiple_use_options: [
        { title: 'Evet', value: true },
        { title: 'Hayır', value: false },
      ],
      max_use_count: null,
      expiration_date: null,

      edit_discount_status: { title: 'Discount Durumu', value: null },
      discount_status_options: [{ title: 'Aktif', value: true }, { title: 'Pasif', value: false }],
      edit_name: null,
      edit_discount_code: null,
      edit_type: { title: 'İndirim Türü', value: null },
      edit_discount_value: null,
      edit_plan_period: { title: 'Geçerli Plan Tütü', value: null },
      edit_multiple_use: { title: 'Hayır', value: false },
      edit_max_use_count: null,
      edit_expiration_date: null,
    }
  },
  computed: {
  },
  watch: {
    filter_type: {handler() {this.getTableData()},},
    filter_plan_period: {handler() {this.getTableData()},},
    filter_multiple_use: {handler() {this.getTableData()},},
    filter_discount_status: {handler() {this.getTableData()},},
    sortBy: {handler() {this.getTableData()},},
    isSortDirDesc: {handler() {this.getTableData()},},
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  setup() {

  },
  methods: {
    getTableData() {
      useJwt.getDiscountList({
        // center_type: this.filter_center_type.value,
        status: this.filter_discount_status.value,
        type: this.filter_type.value,
        plan_period: this.filter_plan_period.value,
        multiple_use: this.filter_multiple_use.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.DiscountDetail(row_id)
        .then(response => {
          this.edit_name = response.data.name
          this.type_options.forEach(vvv => {
            if (vvv.value === response.data.type) {
              this.edit_type = vvv
            }
          })
          this.plan_period_options.forEach(vvv => {
            if (vvv.value === response.data.plan_period) {
              this.edit_plan_period = vvv
            }
          })
          this.edit_discount_value = response.data.discount_value
          this.multiple_use_options.forEach(vvv => {
            if (vvv.value === response.data.multiple_use) {
              this.edit_multiple_use = vvv
            }
          })
          this.edit_discount_code = response.data.discount_code
          this.edit_max_use_count = response.data.max_use_count
          this.edit_expiration_date = response.data.expiration_date
          this.discount_status_options.forEach(vvv => {
            if (vvv.value === response.data.status) {
              this.edit_discount_status = vvv
            }
          })
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.DiscountDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('type', this.type.value)
      formData.append('plan_period', this.plan_period.value)
      formData.append('discount_value', this.discount_value)
      formData.append('multiple_use', this.multiple_use.value)
      formData.append('discount_code', this.discount_code)
      formData.append('max_use_count', this.max_use_count)
      formData.append('expiration_date', this.expiration_date)
      formData.append('status', true)

      useJwt.createDiscount(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('name', this.edit_name)
      formData.append('status', this.edit_discount_status.value)
      formData.append('type', this.edit_type.value)
      formData.append('plan_period', this.edit_plan_period.value)
      formData.append('discount_value', this.edit_discount_value)
      formData.append('multiple_use', this.edit_multiple_use.value)
      formData.append('discount_code', this.edit_discount_code)
      formData.append('max_use_count', this.edit_max_use_count)
      formData.append('expiration_date', this.edit_expiration_date)
      useJwt.updateDiscount(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.DiscountDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal() {
      this.$refs.new_record_modal.hide()
      this.Discount_name = null
      this.Discount_status = { title: 'Discount Durumu', value: null }
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.selected_row_id = null
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
